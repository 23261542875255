import React from 'react';
import './index.css';
import App from './App.jsx';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();

caches.keys().then(function(names) {
    for (let name of names) {
        caches.delete(name);
    }
});

navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
        console.log(registration)
        registration.unregister();
    }
});