import Grid from "@mui/material/Grid";
import DanceDay from "./DanceDay.jsx";
import React, {useState, useEffect, useContext} from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import cookie from "cookie";
import Alert from "@mui/material/Alert";
import {toYYYYMMDD} from "../date.js";
import StarIcon from '@mui/icons-material/Star';
import userContext from "./UserContext.jsx";
import Link from "@mui/material/Link";
import {getEvents, mapEvents} from "../EventLoader.jsx";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import * as quickStart from "./TwoStepGuide.jsx";

const DanceEventList = (props) => {

  const user = useContext(userContext);
  const cookies = cookie.parse(document.cookie);
  var initialDanceable = false;
  var initialShowLessons = true;
  var initialMyEventsOnly = false;
  if (cookies.hasOwnProperty("danceableOnly") && cookies.danceableOnly === "1") {
      initialDanceable = true;
  }
  if (cookies.hasOwnProperty("showLessons") && cookies.showLessons === "0") {
    initialShowLessons = false;
  }
  if (cookies.hasOwnProperty("myEventsOnly") && cookies.myEventsOnly === "1") {
      initialMyEventsOnly = true;
  }
  const [events, setEvents] = useState([]);
  const [danceableOnly, setDanceableOnly] = useState(initialDanceable);
  const [showLessons, setShowLessons] = useState(initialShowLessons);
  const [myEventsOnly, setMyEventsOnly] = useState(initialMyEventsOnly);

  const filterPast = (events) => {
      const date = new Date();
      date.setHours(date.getHours() - 12);
      const dateStr = toYYYYMMDD(date);
      return events.filter((e) => e.date >= dateStr)
  }

    const filterEvents = (eventList, danceableOnly, showLessons, myEventsOnly) => {

      document.cookie = "danceableOnly=" + (danceableOnly ? "1" : "0") + "; max-age=31104000";
      document.cookie = "showLessons=" + (showLessons ? "1" : "0") + "; max-age=31104000";
      document.cookie = "myEventsOnly=" + (myEventsOnly ? "1" : "0") + "; max-age=31104000";

      eventList = filterPast(eventList)

      if (danceableOnly) {
          eventList = eventList.filter(e => e.danceable);
      }
      if (!showLessons) {
          eventList = eventList.filter(e => !(e.type === "lesson"))
      }
      if (myEventsOnly) {
          eventList = eventList.filter(e => user.rsvpMap.hasOwnProperty(e.date + "-" + e.venueKey))
      }

      return eventList;
  }



  useEffect(() => {
    //ensure a refresh every hour
    setInterval(() => getEvents((e) => setEvents(e)), 300 * 1000);
    getEvents((e) => setEvents(e));
    // getRSVP();
  }, [])

    const danceableLabel = <strong><Link underline={"none"}>Dancer Favorites Only</Link></strong>;
    const lessonLabel = <strong>Lessons</strong>;
    const myEventsLabel = <strong>Only starred events <StarIcon style={{color: "yellow", position: "relative", top: "5px"}} /></strong>;

    const toggleDanceable = (e) => {
        setDanceableOnly(e.target.checked);
    }
    const toggleLessons = (e) => {
        setShowLessons(e.target.checked);
    }
    const toggleMyEventsOnly = (e) => {
        setMyEventsOnly(e.target.checked);
    }



    var allEvents = mapEvents(filterEvents(events, danceableOnly, showLessons, myEventsOnly));

    let contents;
    if (events === null) {
        contents = <Alert style={{width: "100%"}}>Loading Events</Alert>
    } else if (!props.isLoggedIn && myEventsOnly) {
        contents = <Alert style={{width: "100%"}} variant={"filled"} severity={"error"}>You must be logged in to show starred events. Disable filter or log-in to continue.</Alert>
    } else {
        contents = allEvents.map((danceDay) => <Grid item key={danceDay.date} xs={12} style={{paddingBottom: "20px"}}><DanceDay key={danceableOnly.toString()} danceDay={danceDay} danceableOnly={danceableOnly} isLoggedIn={props.isLoggedIn}/></Grid>)
    }

    return <Grid container>
            <Grid item key={"danceable"} xs={12} style={{paddingBottom: "20px", textAlign:"center"}}>

                        <Button startIcon={<InfoIcon />} variant={"contained"} color={"success"} href={quickStart.uri}>{quickStart.title}</Button>

            </Grid>
            <Grid item key={"danceable"} xs={12} style={{paddingBottom: "20px"}}>
                <Card>
                    <CardContent>
                        <FormControlLabel label={danceableLabel} control={<Switch color={"primary"} checked={danceableOnly} onChange={toggleDanceable} />} />
                        <FormControlLabel label={lessonLabel} control={<Switch color={"primary"} checked={showLessons} onChange={toggleLessons} />} />
                        <FormControlLabel label={myEventsLabel} control={<Switch color={"primary"} checked={myEventsOnly} onChange={toggleMyEventsOnly} />} />
                        {/*<AddEvent />*/}
                    </CardContent>
                </Card>
            </Grid>
            {contents}
          </Grid>
}

export default DanceEventList