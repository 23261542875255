import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import {dateToHumanDateStr} from "../date.js";
import UserContext from "./UserContext.jsx";
import {Event} from "./Event.jsx";
import Button from "@mui/material/Button";

const DanceDay = (props) => {

    const date = new Date(props.danceDay.date + "T12:00:00");
    const dstr = dateToHumanDateStr(date);
    const rsvpDate = props.danceDay.date;
    const data = props.danceDay.data;

    const user = useContext(UserContext);



    return (
        <Card variant={"outlined"}>
                <CardContent>
                    <Button size={"small"} style={{float: "right", top: 0}} href={"/calendar/" + rsvpDate}>Details</Button>
                    <Typography variant="h6">{dstr}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        {data.slice(0, Math.ceil(data.length / 2)).map((venue) =>
                            <Event venue={venue} rsvpDate={rsvpDate} user={user} date={dstr} />
                        )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        {data.slice(Math.ceil(data.length / 2), data.length).map((venue, i) =>
                            <div key={i}>
                                <Event venue={venue} rsvpDate={rsvpDate} user={user} date={dstr} />
                            </div>
                        )}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
    );
}

export default DanceDay;