import React, {useContext, useEffect, useState} from "react";
import UserContext from "./UserContext.jsx";
import {getEvents} from "../EventLoader.jsx";
import {venueMap} from "../venue.js";
import Grid from "@mui/material/Grid";
import {BackButton} from "./BackButton.jsx";
import {EventCard} from "./EventCard.jsx";

export const EventPage = (props) => {

    const {venueKey, date} = props.match.params;
    let [events, setEvents] = useState([]);
    const user = useContext(UserContext);

    useEffect(() => {
        getEvents((e) => setEvents(e))
    }, [])

    events = events.filter(e => e.venueKey === venueKey && e.date === date)

    const v = venueMap[venueKey];

    return <Grid container>
            <Grid item xs={12}>
                <BackButton />
                <EventCard v={v} events={events} user={user} date={date}/>
            </Grid>
        </Grid>
}