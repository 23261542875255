import {CardContent, CardHeader, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import {BackButton} from "./BackButton.jsx";
import {Helmet} from "react-helmet";
import React from "react";

export const title = "New to 2-Steppin in Austin?";
export const desc = "Here's what you need to know to have a good time!"
export const uri = "quick-start-austin-2-step-guide"

export const TwoStepGuide = (props) => <div>
    <Helmet>
        <meta charSet="utf-8"/>
        <meta property="og:description" content={title} />
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={desc}/>
        <title>{title}</title>
        <meta property="description" content={desc} />
        <link rel="canonical" href={"https://austin2step.com/" + uri}/>
    </Helmet>

    <BackButton />
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardHeader title={title} subheader={desc}/>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardHeader title={"What is 2-step?"} subheader={"The short version"}/>
                <CardContent>
                    <p>There is nothing more "Austin" than going 2-stepping. 2-Step, a country western dance that goes back over 120 years in in Texas, is a fun, easy to learn, social dance seen in honky tonks and dancehalls all over Texas.</p>
                    <p>While many will passionately debate what is the real Texas 2-step, including myself, there are many styles all based of the same original steps. So while you may hear either Quick-Quick-Slow OR Quick-Quick-Slow-Slow OR Slow-Slow-Quick-Quick, they are all in practice, identical. You learn one, its a small leap to learn another.</p>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardHeader title={"How and Where to dance?"} subheader={""}/>
                <CardContent>
                    <Typography variant={"overline"}>LEARN TO DANCE</Typography>
                    <p>Taking a drop-in lesson is the quickest (and entertaining) way to learn 2-step. There are drop-in lessons almost every single day in Austin, so there's no excuse! Just show up on time and let the good times roll.</p>
                    <p>All of the drop-in lessons in Austin are our <Link href={"/twostep-lessons"}>2 Step Lessons</Link> page.</p>
                    <Typography variant={"overline"}>WHERE TO DANCE</Typography>
                    <p>Honky Tonkers dance to live music, there is no substitute. Fortunately you can find all of our favorite dance bands our <Link href={"/"}>Dance Calendar</Link>. Generally bands in blue are the best chances to get lots of dances.</p>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardHeader title={"What else do I need to know?"}/>
                <CardContent>
                    <p>A little knowledge of customs and traditions goes a long way.</p>
                    <Typography variant={"overline"}>Ettiquette</Typography>
                    <ul>
                        <li>No drinks on the dance floor --- EVER --- Partner dancing is much more active than solo club dancing, and spilled drinks make things dangerous.</li>
                        <li>Treat everyone with respect. This is our happy place. Everyone is welcome. Be excellent to each other.</li>
                        <li>Ask people to dance, most of us will say yes. If someone says no, just say "ok, thanks" or "have a goodnight". Don't get upset when people say no, it happens.</li>
                        <li>Feel free to say yes or no when someone asks you to dance. Its your choice. "no thanks" is fine.</li>
                        <li>Different venues follow different "line of dance" rules. Larger dancehalls will traditionally travel around the outside of the dance floor in a counter-clockwise manner. Smaller places will dance in place. Some venues are mixed. If you are on a dance floor with traveling, but want to dance in place, the middle of the dance floor is the best place.</li>
                    </ul>
                    <Typography variant={"overline"}>Pro-Tips</Typography>
                    <ul>
                        <li>Dance with as many people as possible, nothing will improve your skills faster</li>
                        <li>Wearing dancing shoes! Boots with leather soles are the best, sneakers will do in a pinch, sandals are terrible.</li>
                        <li>Travel light! Try to leave purses at home or stash them. Swinging purses on the dance floor are not fun.</li>
                        <li>Stand near the edge of the dance floor to get more dances. Sitting or being far away will reduce your chances of being asked to dance.</li>
                        <li>Also if you are bundled up in a coat, people may assume you about to leave, and sometimes won't ask you.</li>
                    </ul>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
</div>