import Card from "@mui/material/Card";
import {CardContent, CardHeader, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {InstagramEmbed} from "react-social-media-embed";
import Alert from "@mui/material/Alert";
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import {venueMap} from "../venue.js";

const desc = "More info on the holiday 2-steppin for Christmas Eve, Christmas and New Years Eve";
const title = "Holiday 2-steppin";

export class Event {
    constructor(date, venueKey, insta, cover) {
        Object.assign(this, {
            date: date,
            venueKey: venueKey,
            insta: insta,
            cover: cover
        })
    }
}

// const events = [
//     new Event("2024-12-31", "atx-highnoon", "https://www.instagram.com/p/DDGLc7qNASD/", "$20 door"),
//     new Event("2024-12-31", "atx-sagebrush", "https://www.instagram.com/p/DCQAVELNpin/", "$25 presale / $30 door")
//
// ].reduce((acc, e) => {
//     if (!acc.hasOwnProperty(e.date)) {
//         acc[e.date] = [];
//     }
//     acc[e.date].push(e);
//     return acc
// }, {});

const days = {
    "2024-12-24": "Christmas Eve",
    "2024-12-25": "Christmas Day",
    "2024-12-31": "New Year's Eve",
    "2025-01-01": "New Year's Day",
}
const dayKeys = Object.keys(days);

export const HolidayGuide = (props) => {

    let [events, setEvents] = useState([]);

    useEffect(() => {
        fetch("/holiday.json")
            .then(res => res.json())
            .then(list => list.reduce((acc, e) => {
                 if (!acc.hasOwnProperty(e.date)) {
                     acc[e.date] = [];
                 }
                 acc[e.date].push(e);
                 return acc
             }, {}))
            .then(e => setEvents(e))
    }, []);

    return <Grid container spacing={2}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta property="og:description" content={desc}/>
                    <meta property="twitter:title" content={title}/>
                    <meta property="twitter:description" content={desc}/>
                    <title>{title}</title>
                    <meta property="description" content={desc} />
                    <link rel="canonical" href="https://austin2step.com/holiday-2-steppin"/>
                </Helmet>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={title} />
                        <CardContent>
                            The main calendar has all of the usual info. This is for the posters, socials and covers for Christmas Eve/Day and New Years Eve/Day.
                        </CardContent>
                    </Card>
                </Grid>
                {dayKeys.map(k =>
                    <>
                        {/* date header */}
                        <Grid item xs={12}>
                            <Alert icon={false} severity={"info"}><Typography variant={"h6"}>{days[k]}</Typography></Alert>
                        </Grid>

                        {/*events for this date*/}
                        {events.hasOwnProperty(k) && events[k].map(e =>
                                <Grid item xs={12} md={6} key={e.venueKey}>
                                    <Card>
                                        <CardContent style={{textAlign:"center"}}>
                                            <InstagramEmbed url={e.insta} width={350} />
                                            <p>{venueMap[e.venueKey].name} <i>({venueMap[e.venueKey].location})</i></p>
                                            <p>Cover: {e.cover}</p>

                                        </CardContent>
                                    </Card>
                                </Grid>
                        )}

                        {!events.hasOwnProperty(k) && <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            No events added yet
                                        </CardContent>
                                    </Card>
                                </Grid>
                        }

                    </>
                )}
            </Grid>

}