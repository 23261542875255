import React, {useContext, useEffect, useState} from "react";
import {RSVPButton} from "./RSVP.jsx";
import Link from "@mui/material/Link";
import {
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogTitle,
    IconButton, Snackbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import ReadMoreTwoToneIcon from '@mui/icons-material/ReadMoreTwoTone';
import Card from "@mui/material/Card";
import {venueMap} from "../venue.js";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

export const copyToClipboard = (e, input, user) => {
    e.preventDefault();
    navigator.clipboard.writeText(input);
    user.setSnackBar({open: true, message: "Copied to Clipboard"})
}

export const Event = (props) => {
    const {venue, rsvpDate, user} = props;
    let [detailOpen, setDetailOpen] = useState(false);

    const link = venue.data.reduce((acc, slot) => {
        if (slot.link !== undefined) {
            acc = slot.link
        }
        return acc;
    }, false)


    return  <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                <div>
                    <span style={{fontSize: "125%"}}>{venue.venue}</span>
                    {user.loggedIn && <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                venueKey={venue.venueKey}/>}
                    <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "0px"}} variant={"contained"} href={`/event/${venue.venueKey}/${rsvpDate}`}><ReadMoreTwoToneIcon color={"primary"} /></IconButton>
                    {link && <div><Link href={link} target={"_blank"}>{link}</Link></div>}
                </div>
                <div>
                    {venue.data.map((slots, i) => <div
                        key={i}><strong>{slots.time} </strong>{slots.danceable ?
                        <strong><Link
                            href={"/artist/" + slots.bandKey}>{slots.band}</Link></strong> : slots.band} {slots.venmo &&
                        <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})}
                              size={"small"} color={"info"} label={"tip"} component={"a"}
                              clickable/>}</div>)}
                </div>
                {detailOpen && <EventDetail {...props} setDetailOpen={setDetailOpen}/>}
            </div>
}


export const EventDetail = (props) => {
    const {venue, rsvpDate, user, date, setDetailOpen} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const vKey = venue.venueKey;
    const v = venueMap[vKey];


    return <Dialog open={true} fullScreen={fullScreen} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>
                <div style={{float: "right"}}><Button color={"primary"} variant={"contained"} onClick={() => setDetailOpen(false)}><CloseIcon /></Button></div>
                <div><em>{venue.venue}</em></div>
            </DialogTitle>
            <Card>
                <CardContent>
                    <h5>Lineup for {date}</h5>
                    <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                        <div>
                            {user.loggedIn && <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                                          venueKey={venue.venueKey}/>}
                        </div>
                        <div>
                            {venue.data.map((slots, i) => <div
                                key={i}><strong>{slots.time}</strong> {slots.danceable ?
                                <strong>
                                    <Link href={"/artist/" + slots.bandKey}>{slots.band}</Link>
                                </strong> : slots.band}
                                {slots.tag && <span> <i>{slots.tag}</i> </span>}
                                {slots.venmo &&
                                    <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})}
                                          size={"small"} color={"info"} label={"tip"} component={"a"}
                                          clickable/>}</div>)}
                        </div>
                        <h5>Details</h5>
                        <div><small>Location: {v.location}</small></div>
                        <div><small>Cover: {v.cover}</small></div>
                        <div><small>Ages: {v.age}</small></div>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
}

